@import "../../assets/styles/mixins.scss";

.app-background {
  background-image: url("./../../assets/graphics/App_background.jpg");
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
}

.remember-me-label {
  @include app-width("margin-right", 35);
  @include app-width("padding-top", 3);
}
