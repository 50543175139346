@import "../../../assets/styles/mixins.scss";

.wrapper-dashboard {
  .sidebar {
    background-color: var(--color-maroon);
    color: var(--color-white);
    max-height: 100vh;
    min-height: 100vh;
    position: sticky;
    top: 0;
    @include app-width("width", 350);

    .simplebar-wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;

      .simplebar-content > * {
        @include app-width("padding-left", 30);
        @include app-width("padding-right", 22);
      }
    }
    .simplebar-scrollbar:before {
      background-color: var(--color-white);
    }

    .logo {
      @include app-width("padding-top", 50);

      svg {
        @include app-width("height", 102);
        @include app-width("width", 163);
      }
    }

    .production-selector {
      @include app-width("margin-top", 40);
    }

    .form-select {
      background-color: var(--color-maroon-sidebar-select);
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23FFFFFF%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
      border-color: var(--color-maroon);
      border-style: solid;
      @include app-width("border-width", 1);
      color: var(--color-white);
      @include fs-18;
      @include app-width("height", 60);
      @include app-width("padding", 16);

      &:focus {
        border-color: var(--color-maroon-dark);
        box-shadow: 0 0 0 0.25rem rgba(var(--color-maroon-dark-rgb), 0.25);
      }
    }

    &-production-info {
      @include app-width("margin-top", 30);

      .avatar {
        background-color: #99999933;
        border-color: var(--color-maroon-dark);
        border-style: solid;
        @include app-width("border-width", 3);
        @include app-width("border-radius", 60);
        display: flex;
        @include app-width("height", 104);
        position: relative;
        @include app-width("width", 104);

        img {
          @include app-width("border-radius", 60);
          height: 100%;
          object-fit: cover;
          position: relative;
          overflow: hidden;
          width: 100%;
        }

        .graphic-wrap.noimage.production {
          align-items: center;
          display: flex;
          justify-content: center;
          position: relative;

          svg {
            width: 50%;
          }
        }

        .icon-wrap {
          left: 85%;
          position: absolute;
          top: 85%;
          transform: translate(-50%, -50%);
          z-index: 1;

          svg {
            @include app-width("height", 50);
            @include app-width("width", 50);
          }
        }
      }

      .info {
        @include app-width("margin-top", 30);

        .title {
          color: inherit;
          @include font-600;
          @include fs-18;
          margin: 0;
        }
      }
    }

    &-menu {
      @include app-width("margin-top", 146);

      nav {
        .nav-item {
          align-items: center;
          background-color: transparent;
          border: 0;
          @include app-width("border-radius", 10);
          color: inherit;
          display: flex;
          @include font-500;
          @include fs-18;
          @include app-width("height", 60);
          padding: 0 15px;
          text-decoration: none;

          &.current-page {
            background-color: var(--color-gold);
            color: var(--color-gold-dark);
          }

          + .nav-item {
            @include app-width("margin-top", 10);
          }

          .icon-wrap {
            align-items: center;
            display: flex;
            @include app-width("height", 42);
            justify-content: center;
            @include app-width("margin-left", -6);
            @include app-width("margin-right", 22);
            @include app-width("width", 42);

            svg {
              @include app-width("height", 29);
              @include app-width("min-width", 29);
              @include app-width("max-width", 29);
            }
          }

          .link-title {
            color: inherit;
          }
        }
      }
    }

    .button-new-production {
      @include app-width("margin-top", 40);
      @include app-width("padding-bottom", 22);

      button {
        background-color: var(--color-maroon-dark);
        border-color: var(--color-maroon-dark);
        @include font-500;
        @include fs-18;
        @include app-width("height", 60);
      }
    }
  }
}
