:root {
  --color-maroon: #930c1c;
  --color-maroon-rgb: 147, 12, 28;
  --color-maroon-sidebar-select: #860f1d;
  --color-maroon-dark: #69040d;
  --color-maroon-dark-rgb: 105, 4, 12;
  --color-maroon-calendar: #6a1d24;
  --color-gold: #e1c99d;
  --color-gold-dark: #4d3a17;

  --color-white: #ffffff;
  --color-grey: #a0a9b6;
  --color-grey-rgb: 160, 169, 182;
  --color-grey-light: #ecedef;
  --color-grey-main-top-bar: #f5f3f3;
  --color-grey-lighter: #f5f5f5;
  --color-grey-dark: #555; // checkbox
  --color-grey-darker: #666; // checkbox
  --color-grey-light-text: #b0b9c7; // conflict user descr

  --color-border: #e2e2e2;
  --color-border-darker: #bfbfbf;

  --color-black: #000;

  --color-text-dark: #2e2e30;

  --color-dark-blue: #001f47;
}
